





























































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    image01(): string {
      if (this.isMobile) {
        return this.isCN
          ? this.oss.am_hatsu_battleship_01_mobile_cn
          : this.oss.am_hatsu_battleship_01_mobile_en;
      } else {
        return this.isCN
          ? this.oss.am_hatsu_battleship_01_cn
          : this.oss.am_hatsu_battleship_01_en;
      }
    },
    title04(): string {
      if (this.isCN && !this.isMobile) {
        return String(this.$t("AMHatsuBattleship.title04")).replace(
          "<br />",
          " "
        );
      } else {
        return String(this.$t("AMHatsuBattleship.title04"));
      }
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_hatsu);
    },
    onVerify() {
      window.open(this.$paths.verify + "am-hatsu/", "_blank");
    }
  }
});
